import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Icon,
  MaxWidthText,
} from '@makeably/creativex-design-system';
import Modal from 'components/reusable/modal.coffee';
import {
  auditScorecardPath,
} from 'utilities/routes';
import styles from './CreativeCell.module.css';

const propTypes = {
  item: PropTypes.shape({
    assetId: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    children: PropTypes.object,
    creativeName: PropTypes.string,
    creativeThumbnail: PropTypes.string,
    isChild: PropTypes.bool,
    isVideo: PropTypes.bool,
    loadOnOpen: PropTypes.bool,
    pretestProcessingStatus: PropTypes.string,
    pretestStatus: PropTypes.string,
  }).isRequired,
};

const MODAL_ID_PREFIX = 'pretest_creative_viewer_';

function CreativeCell({
  item: {
    assetId,
    children,
    creativeName,
    creativeThumbnail,
    isChild,
    isVideo,
    pretestProcessingStatus,
    pretestStatus,
  },

}) {
  const hasChildren = children && children.length > 0;
  const dataTarget = MODAL_ID_PREFIX + assetId;

  const thumbnailClasses = classNames(
    styles.creativeContainer,
    { [styles.creativeContainerHover]: isChild || !hasChildren },
  );
  const thumbnail = (
    <div className={thumbnailClasses} data-target={!hasChildren && dataTarget}>
      { isVideo && (
        <div className={styles.videoContainer}>
          <Icon color="white" name="play" />
        </div>
      ) }
      <img alt="creative thumbnail" src={creativeThumbnail} />
    </div>
  );

  const thumbnailWithChildren = (
    <div className={styles.badgeContainer}>
      { thumbnail }
      <div className={styles.badge}>{ children?.length }</div>
    </div>
  );

  const containerClasses = classNames(
    styles.details,
    { [styles.margin]: isChild },
  );

  return (
    <div className={containerClasses}>
      { assetId && (
        <Modal
          className="scorecard-container"
          contentLocation={auditScorecardPath(assetId, {
            include_matched_posts: false,
            pretest_status: pretestStatus ? 'completed' : 'in-review',
          })}
          id={dataTarget}
          loadOnOpen
        />
      ) }
      { hasChildren ? thumbnailWithChildren : thumbnail }
      <div className={styles.status}>
        <MaxWidthText text={creativeName}>
          { creativeName }
        </MaxWidthText>
        <span>{ pretestProcessingStatus }</span>
      </div>
    </div>
  );
}

CreativeCell.propTypes = propTypes;

export default CreativeCell;
