import React, {
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Button,
  Card,
  Checkbox,
  ClickableTag,
  Divider,
  Icon,
  MoreButton,
  Pagination,
  Search,
  Spinner,
  Table,
  Tag,
} from '@makeably/creativex-design-system';
import DsTabs from 'components/molecules/DsTabs';
import SubmitAsCoreAssetModal from 'components/preflights/SubmitAsCoreAssetModal';
import ScoreFilter from 'components/reporting/ScoreFilter';
import { arrayIf } from 'utilities/array';
import { getObjFilterTest } from 'utilities/filtering';
import { removeProperty } from 'utilities/object';
import { get } from 'utilities/requests';
import {
  newPreflightPretestPath,
  pdfDataPreflightPretestsPath,
  preflightPretestsPath,
} from 'utilities/routes';
import CreativeCell from './CreativeCell';
import styles from './SubmittedPreflights.module.css';

const MAX_PER_PAGE = 20;
const NULL_VALUE = '--';

const propTypes = {
  creativeLifecycle: PropTypes.shape({
    coreAssetIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    creativeLifecycleCampaigns: PropTypes.arrayOf(
      PropTypes.shape({
        brand: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ).isRequired,
    partners: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ).isRequired,
  }).isRequired,
  featureAccess: PropTypes.shape({
    canViewTabs: PropTypes.bool.isRequired,
    displayBrand: PropTypes.bool.isRequired,
    displayQuality: PropTypes.bool.isRequired,
    isCreativeLifecycleEnabled: PropTypes.bool.isRequired,
  }).isRequired,
  preflights: PropTypes.arrayOf(
    PropTypes.shape({
      assetId: PropTypes.number,
      brandConsistent: PropTypes.bool,
      channel: PropTypes.string,
      creativeName: PropTypes.string,
      creativeThumbnail: PropTypes.string,
      excellentCreatives: PropTypes.string,
      isPreflightReviewed: PropTypes.bool,
      isVideo: PropTypes.bool,
      partner: PropTypes.string,
      pretestProcessingStatus: PropTypes.string,
      pretestStatus: PropTypes.string,
      regulatoryResult: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      ruleResults: PropTypes.object,
      rulesMet: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      scores: PropTypes.object,
      submittedTime: PropTypes.string,
    }),
  ).isRequired,
  scores: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    rankLabels: PropTypes.object.isRequired,
    scoreId: PropTypes.number.isRequired,
    scoreName: PropTypes.string.isRequired,
  }).isRequired,
  youtubeType: PropTypes.string.isRequired,
  currentTab: PropTypes.string,

};

const defaultProps = {
  currentTab: 'user',
};

function headers(displayBrand, displayQuality, allChecked, onAllChecked, coreAssetSelectVisible) {
  let conditionalHeaders = [];

  if (displayBrand) conditionalHeaders.push({ value: 'Branded' });

  if (displayQuality) {
    conditionalHeaders.unshift({
      className: styles.highQualityColumn,
      value: 'High Quality Creatives',
    });
    conditionalHeaders = conditionalHeaders.concat([
      { value: 'Score' },
      { value: 'Rules Met' },
    ]);
  }

  return [
    ...arrayIf(coreAssetSelectVisible,
      { value: <Checkbox checked={allChecked} onChange={onAllChecked} /> }),
    { value: '' },
    { value: 'Name' },
    { value: 'Submitted' },
    { value: 'Channel' },
    ...conditionalHeaders,
    { value: 'PDF' },
    { value: '' },
  ];
}

function getPreflightsSegments(scoreName) {
  return [{
    label: 'Brand',
    value: 'brand',
  }, {
    label: 'Channel',
    value: 'channel',
  }, {
    label: 'Filename',
    value: 'creativeName',
  }, {
    label: 'Market',
    value: 'market',
  }, {
    label: 'Partner',
    value: 'partner',
  }, {
    label: 'Submission Name',
    value: 'auditName',
  }, {
    label: `${scoreName}: Tiers`,
    value: 'rank',
  }];
}

function downloadFile(url, name) {
  // Create a link and set the URL using `createObjectURL`
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.download = name;

  // It needs to be added to the DOM so it can be clicked
  document.body.appendChild(link);
  link.click();

  // To make this work on Firefox we need to wait
  // a little while before removing it.
  setTimeout(() => {
    URL.revokeObjectURL(link.href);
    link.parentNode.removeChild(link);
  }, 0);
}

function resultCell(result) {
  if (typeof result === 'string') return result;

  const color = result ? 'green' : 'red';
  const icon = result ? 'checkCircle' : 'exclamationCircle';

  return <Icon color={color} name={icon} />;
}

async function onPdfDownload(id) {
  const response = await get(pdfDataPreflightPretestsPath({ preflight_id: id }));
  downloadFile(response.data.url, response.data.name);
}

function preflightProcessingStatus(records) {
  const totalChecks = records.reduce((total, obj) => {
    const innerObject = obj.ruleResults;
    if (innerObject && typeof innerObject === 'object') {
      return total + Object.keys(innerObject).length;
    }
    return total;
  }, 0);
  const completedChecks = records.reduce((total, obj) => {
    const innerObject = obj.ruleResults;
    if (innerObject && typeof innerObject === 'object') {
      return total + Object.values(innerObject).filter((value) => value !== null).length;
    }
    return total;
  }, 0);
  const noGuidelineData = records.reduce((total, obj) => {
    const innerObject = obj.scores;
    if (!innerObject) {
      return total + 1;
    }
    return total;
  }, 0);

  if (records[0].isPreflightReviewed) {
    return 'Completed';
  } else if (noGuidelineData > 0) {
    return 'Processing';
  } else if (completedChecks === totalChecks) {
    return 'Generating PDF';
  }
  return `Processing - ${Math.round((completedChecks / totalChecks) * 100)}%`;
}

function rulesMetString(record) {
  if (record.scores && record.isReviewed) {
    return `${Object.values(record.ruleResults).filter((val) => val !== undefined && val === 1).length}/${Object.keys(record.ruleResults).length}`;
  }
  return NULL_VALUE;
}

function scoreString(record, scoreId) {
  if (record.scores && record.isReviewed) {
    if (record.scores[scoreId].value) {
      return `${Math.round(record.scores[scoreId].value * 100)}%`;
    }
    return 'N/A';
  }
  return NULL_VALUE;
}

function brandResultString(record) {
  if (record.scores && record.isReviewed) {
    return record.brandConsistent;
  }
  return NULL_VALUE;
}

function excellentCreativesString(record, scoreId) {
  if (record.scores && record.isReviewed) {
    return record.scores[scoreId].rank === 'highest';
  }
  return NULL_VALUE;
}

function SubmittedPreflights({
  creativeLifecycle: {
    coreAssetIds,
    creativeLifecycleCampaigns,
    partners,
  },
  currentTab,
  featureAccess: {
    canViewTabs,
    displayBrand,
    displayQuality,
    isCreativeLifecycleEnabled,
  },
  preflights,
  scores: {
    rankLabels,
    scoreId,
    scoreName,
  },
  youtubeType,
}) {
  const currentTabLabel = currentTab === 'all' ? 'All Submissions' : 'My Submissions';
  const segments = getPreflightsSegments(scoreName);

  const [allChecked, setAllChecked] = useState(false);
  const [auditPosts, setAuditPosts] = useState([]);
  const [coreAssetSelectVisible, setCoreAssetSelectVisible] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedAuditIds, setSelectedAuditIds] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [tableDataWithParents, setTableDataWithParents] = useState([]);

  function getCreativeName(record, isChild) {
    if (isChild) {
      if (record.creativeSourceType === youtubeType && record.title) {
        return record.title;
      }
      return record.filename || record.creativeThumbnail.split('/').slice(-1)[0];
    }
    return record.auditName;
  }

  function getRank(record) {
    return record.scores ? rankLabels[record.scores[scoreId].rank] : undefined;
  }

  const addParentRows = (rows) => Object.entries(
    Object.groupBy(rows, ({ auditId }) => auditId),
  ).map(([auditId, records]) => {
    // Use the first entry for the audit fields that
    // are the same for all posts (channel, submitted, etc...)
    const post = records[0];
    const singlePostReviewed = post.isReviewed && post.scores;
    if (records.length > 1) {
      // Assign values based on the summary data
      const extendedRecords = records.map((record) => ({
        ...record,
        brandResult: brandResultString(record),
        creativeName: getCreativeName(record, true),
        excellentCreatives: excellentCreativesString(record, scoreId),
        id: `${record.auditId}_${record.postId}`,
        isChild: true,
        isVisible: true,
        rank: getRank(record),
        rulesMet: rulesMetString(record),
        score: scoreString(record, scoreId),
      }));

      // If there are child records, we need to create a parent record
      // Default is that the post is not reviewed, in which case we cannot summarize columns
      const parentRecord = {
        brandConsistent: NULL_VALUE,
        brandResult: NULL_VALUE,
        channel: post.channel,
        children: extendedRecords,
        creativeName: getCreativeName(post, false),
        creativeThumbnail: post.creativeThumbnail,
        excellentCreatives: NULL_VALUE,
        id: auditId,
        isPreflightReviewed: post.isPreflightReviewed,
        pretestProcessingStatus: preflightProcessingStatus(records),
        regulatoryResult: NULL_VALUE,
        rulesMet: NULL_VALUE,
        score: NULL_VALUE,
        submittedTime: post.submittedTime,
      };

      // If the post is reviewed, the parent can summarize the child columns
      if (post.isPreflightReviewed) {
        const totalCreatives = extendedRecords.length;
        const excellentCreatives = extendedRecords.filter((hash) => hash.excellentCreatives).length;
        const missingBrandCreativeCount = extendedRecords.filter(
          (hash) => hash.brandConsistent === undefined,
        ).length;
        const brandedCreativeCount = extendedRecords.filter(
          (hash) => hash.brandConsistent === true,
        ).length;

        parentRecord.excellentCreatives = `${excellentCreatives}/${totalCreatives}`;

        if (missingBrandCreativeCount !== totalCreatives) {
          parentRecord.brandResult = `${brandedCreativeCount}/${totalCreatives}`;
        }

        parentRecord.score = 'Multiple'; // exposed on the child row
        parentRecord.rulesMet = 'Multiple'; // exposed on the child row
      }
      return parentRecord;
    } else if (singlePostReviewed) {
      // This case is for when the audit post has run through
      // the guideline data worker so guidelineData is populated.
      return {
        ...post,
        brandResult: post.brandConsistent ? post.brandConsistent : NULL_VALUE,
        creativeName: getCreativeName(post, false),
        excellentCreatives: post.scores[scoreId].rank === 'highest',
        id: `${post.auditId}_${post.postId}`,
        pretestProcessingStatus: preflightProcessingStatus(records),
        rank: getRank(post),
        rulesMet: rulesMetString(post),
        score: scoreString(post, scoreId),
      };
    }
    // This case is for when the audit post hasn't run through the guideline data worker yet
    // so guidelineData hasn't been populated yet and will be nil.
    return {
      ...post,
      brandResult: NULL_VALUE,
      creativeName: getCreativeName(post, false),
      excellentCreatives: NULL_VALUE,
      id: `${post.auditId}_${post.postId}`,
      pretestProcessingStatus: preflightProcessingStatus(records),
      rank: post.scores ? rankLabels[post.scores[scoreId].rank] : undefined,
      rulesMet: NULL_VALUE,
      score: NULL_VALUE,
    };
  });

  useEffect(() => {
    setLoading(true);
    const parentRows = addParentRows(preflights, scoreId);
    setTableDataWithParents(parentRows);

    // Expanded data needed to get all filter segments
    const tableData = [];
    parentRows.forEach(
      (data) => {
        if (data.children) {
          tableData.push(...data.children);
        }
        tableData.push(data);
      },
    );
    setAuditPosts(tableData);
  }, [preflights]);

  useEffect(() => {
    setLoading(true);
    setPage(1);
    const filterTest = getObjFilterTest(selectedFilters);
    const lowercaseSearch = search.toLowerCase();

    const filtered = tableDataWithParents.reduce((arr, item) => {
      const itemName = item.creativeName.toLowerCase();
      const itemNameInSearch = itemName.includes(lowercaseSearch);

      if (item.children) {
        const filteredChildren = item.children.filter((child) => {
          const childName = child.creativeName.toLowerCase();
          const inSearch = search !== '' ? itemNameInSearch || childName.includes(lowercaseSearch) : true;

          return filterTest(child) && inSearch;
        });

        if (filteredChildren.length > 0) {
          return [
            ...arr,
            {
              ...item,
              // If there is a search term or selected filters,
              // we want to expand all of the children of all parents
              isExpanded: lowercaseSearch.length > 0 || Object.keys(selectedFilters).length > 0,
              children: filteredChildren,
            },
          ];
        }
      } else if (filterTest(item) && (search === '' || itemNameInSearch)) {
        return [
          ...arr,
          {
            ...item,
            isExpended: false,
          },
        ];
      }
      return [...arr];
    }, []);

    setFilteredRecords(filtered.map((data, index) => ({
      ...data,
      index,
    })).sort((preflight) => preflight.submittedTime).reverse());
  }, [selectedFilters, search, tableDataWithParents]);

  useEffect(() => {
    setPaginatedData(filteredRecords.slice(
      (page - 1) * MAX_PER_PAGE,
      page * MAX_PER_PAGE,
    ));
    setLoading(false);
  }, [page, filteredRecords]);

  const formatRow = (item) => {
    const {
      assetId,
      auditId,
      brandResult,
      channel,
      children,
      creativeName,
      excellentCreatives,
      id,
      index,
      isChild,
      isExpanded,
      isPreflightReviewed,
      isVisible,
      rulesMet,
      score,
      submittedTime,
    } = item;

    // NOTE: Using css to hide non-expanded child rows so
    // that the materialize creative modal doesn't break.
    const visibilityClass = classNames({
      [styles.hiddenCell]: isChild && !isVisible,
    });
    let conditionalCells = [];
    if (displayBrand) conditionalCells.push({ value: resultCell(brandResult) });
    if (displayQuality) {
      conditionalCells.unshift({ value: resultCell(excellentCreatives) });
      conditionalCells = conditionalCells.concat([
        { value: score },
        { value: rulesMet },
      ]);
    }

    let expandButton = '';
    if (children?.length > 0) {
      const onClick = () => {
        if (isExpanded) {
          setPaginatedData(paginatedData.map((row) => {
            if (row.id === id) {
              return {
                ...row,
                isExpanded: false,
              };
            }
            return row;
          }));
        } else {
          setPaginatedData(paginatedData.map((row) => {
            if (row.id === id) {
              return {
                ...row,
                isExpanded: true,
              };
            }
            return row;
          }));
        }
      };

      expandButton = (
        <Button
          iconLeft={isExpanded ? 'chevronDown' : 'chevronRight'}
          variant="round"
          onClick={onClick}
        />
      );
    }

    let downloadButton = '';
    if (!isChild && isPreflightReviewed) {
      downloadButton = <Button iconLeft="download" variant="tertiary" onClick={() => onPdfDownload(auditId)} />;
    }

    const row = {
      key: `${creativeName}_${index}`,
      cells: [
        ...arrayIf(coreAssetSelectVisible, {
          value: <Checkbox
            checked={selectedAuditIds.includes(id)}
            onChange={() => {
              if (children?.length > 0) {
                const ids = children.map((elem) => elem.id);
                if (selectedAuditIds.includes(id)) {
                  setSelectedAuditIds(
                    selectedAuditIds.filter((elem) => elem !== id && !ids.includes(elem)),
                  );
                } else {
                  setSelectedAuditIds(selectedAuditIds.concat(id).concat(...ids));
                  setPaginatedData(paginatedData.map((data) => {
                    if (data.id === id) {
                      return {
                        ...data,
                        isExpanded: true,
                      };
                    }
                    return data;
                  }));
                }
              } else if (selectedAuditIds.includes(id)) {
                setSelectedAuditIds(selectedAuditIds.filter((elem) => elem !== id));
              } else {
                setSelectedAuditIds(selectedAuditIds.concat(id));
              }
            }}
          />,
        }),
        { value: expandButton },
        { value: <CreativeCell item={item} /> },
        {
          value: new Date(submittedTime).toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
        },
        { value: channel },
        ...conditionalCells,
        { value: downloadButton },
        ...arrayIf(coreAssetIds.includes(assetId) && coreAssetSelectVisible, { value: <Tag label="Core Asset" /> }),
      ],
    };

    return {
      ...row,
      cells: row.cells.map((cell) => ({
        ...cell,
        className: visibilityClass,
      })),
    };
  };

  const expandChildren = (parent) => {
    const finalRows = [formatRow(parent)];
    if (parent.isExpanded) {
      parent.children.forEach((child) => finalRows.push(formatRow(child)));
    }
    return finalRows;
  };

  const menuOptions = [
    {
      label: 'Select Core Assets',
      onClick: () => setCoreAssetSelectVisible(true),
    },
  ];

  const tabs = [
    {
      label: 'My Submissions',
      onClick: () => { window.location.href = preflightPretestsPath({ tab: 'user' }); },
    },
    ...arrayIf(canViewTabs, {
      label: 'All Submissions',
      onClick: () => { window.location.href = preflightPretestsPath({ tab: 'all' }); },
    }),
  ];

  const removeSelectedFilter = (key) => {
    setSelectedFilters((last) => removeProperty(last, key));
  };

  const renderFilter = ([key, values]) => {
    const segmentLabel = segments.find((seg) => (seg.value === key))?.label;

    return (
      <ClickableTag
        key={key}
        color="purple"
        label={`${segmentLabel} (${values.length})`}
        onClick={() => setFilterOpen(true)}
        onRemove={() => removeSelectedFilter(key)}
      />
    );
  };

  const onAllChecked = () => {
    if (!allChecked) {
      let allIds = [];
      paginatedData.forEach(({ id, children }) => {
        if (children) {
          allIds = allIds.concat(...children.map((child) => child.id));
        }
        allIds = allIds.concat(id);
      });
      setSelectedAuditIds(allIds);
    } else {
      setSelectedAuditIds([]);
    }
    setAllChecked(!allChecked);
  };

  return (
    <>
      <DsTabs
        currentTab={currentTabLabel}
        tabs={tabs}
        variant="button"
        showSingleTab
      />
      <Card>
        <div className={styles.header}>
          <div className={styles.headerRow}>
            <Search
              placeholder="Search Name"
              value={search}
              onChange={setSearch}
            />
            <ScoreFilter
              isOpen={filterOpen}
              records={auditPosts}
              segments={segments}
              selections={selectedFilters}
              onClose={() => setFilterOpen(false)}
              onOpen={() => setFilterOpen(true)}
              onSelectionsChange={setSelectedFilters}
            />
            { Object.entries(selectedFilters).length > 0
              && (
              <>
                <div className="t-caption-1">Filters:</div>
                { Object.entries(selectedFilters).map((entry) => renderFilter(entry)) }
              </>
              ) }
          </div>
          <div className={styles.headerRow}>
            { coreAssetSelectVisible
              && (
                <>
                  <Button
                    label="Cancel"
                    variant="secondary"
                    onClick={() => setCoreAssetSelectVisible(false)}
                  />
                  <Button
                    disabled={selectedAuditIds.length === 0}
                    label="Add to Core Assets"
                    onClick={() => setModalOpen(true)}
                  />
                </>
              ) }
            { isCreativeLifecycleEnabled && !coreAssetSelectVisible && paginatedData.length > 0
              && <MoreButton options={menuOptions} /> }
            { !coreAssetSelectVisible
              && <AddNewButton label="Submit New" onClick={() => { window.location.href = newPreflightPretestPath(); }} /> }
          </div>
        </div>
        <Divider margin />
        { loading && (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        ) }
        { !loading && (
          <Table
            className={styles.table}
            headers={headers(
              displayBrand,
              displayQuality,
              allChecked,
              onAllChecked,
              coreAssetSelectVisible,
            )}
            rows={paginatedData.map(expandChildren).flat()}
          />
        ) }
        { filteredRecords && filteredRecords.length > MAX_PER_PAGE && (
          <div className={styles.pagination}>
            <Pagination
              currentPage={page}
              perPage={MAX_PER_PAGE}
              total={filteredRecords.length}
              onPageChange={(newPage) => setPage(newPage)}
            />
          </div>
        ) }
      </Card>
      <SubmitAsCoreAssetModal
        creativeLifecycleCampaigns={creativeLifecycleCampaigns}
        isOpen={modalOpen}
        partners={partners}
        selectedAuditIds={selectedAuditIds}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
}

SubmittedPreflights.propTypes = propTypes;
SubmittedPreflights.defaultProps = defaultProps;

export default SubmittedPreflights;
