import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  MessageModal,
  Dropdown,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import {
  track,
} from 'utilities/mixpanel';
import { post } from 'utilities/requests';
import {
  addAssetsCreativeLifecycleCoreAssetUploadsUrl,
  newCreativeLifecycleCoreAssetUploadPath,
} from 'utilities/routes';
import styles from './SubmitAsCoreAssetModal.module.css';

const propTypes = {
  creativeLifecycleCampaigns: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
  isOpen: PropTypes.bool.isRequired,
  partners: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  })).isRequired,
  selectedAuditIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
};

function SubmitAsCoreAssetModal({
  isOpen, onClose, partners, creativeLifecycleCampaigns, selectedAuditIds,
}) {
  const [campaign, setCampaign] = useState(null);
  const [partner, setPartner] = useState(null);

  const addCoreAssets = async () => {
    track('submit_core_asset', { preflight: true });
    const { data } = await post(addAssetsCreativeLifecycleCoreAssetUploadsUrl(), {
      campaign_id: campaign.value,
      partner_id: partner.value,
      selected_audit_ids: selectedAuditIds,
    });
    if (data.success) {
      track('submit_success');
      addToast(`(${selectedAuditIds.length}) Core assets successfully linked.`);
    } else {
      addToast(`(${data.errors.length}/${selectedAuditIds.length}) Core assets failed to upload: Previously uploaded with a different production partner.`, {
        type: 'error',
        size: 'large',
      });
    }
    window.location.reload();
  };

  return (
    <MessageModal
      actionButtonDisabled={!campaign || !partner}
      actionButtonLabel="Link Assets"
      isOpen={isOpen}
      size="medium"
      title="Add to Core Assets"
      onActionButtonClick={() => addCoreAssets()}
      onClose={onClose}
    >
      <div className={styles.container}>
        <div>
          Select which campaigns you&apos;d like to add your Pre-Flight assets to.
          To create a new campaign, please visit the
          { ' ' }
          <a href={newCreativeLifecycleCoreAssetUploadPath()}>Upload Core Assets</a>
          { ' ' }
          page.
        </div>
        <div className={styles.dropdownRow}>
          <Dropdown
            label="Campaign Name"
            menuProps={{ size: 'large' }}
            options={creativeLifecycleCampaigns}
            selected={campaign}
            size="large"
            onChange={(opt) => setCampaign(opt)}
          />
          <Dropdown
            label="Production Partner"
            menuProps={{ size: 'large' }}
            options={partners}
            selected={partner}
            size="large"
            onChange={(opt) => setPartner(opt)}
          />
        </div>
      </div>
    </MessageModal>
  );
}

SubmitAsCoreAssetModal.propTypes = propTypes;

export default SubmitAsCoreAssetModal;
